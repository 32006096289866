.blocks-style-1{
	.block{
		border: 1px solid $resourcesBg;
		padding: 13px;
		border-radius: 15px;
		margin-bottom: 10px;
		@include clearfix();
		.img{
			float: left;
			width: 146px;
			margin-bottom: 10px;
		}
		.block-content{
			margin-left: 164px;
			h3{
				margin-top: 0;
				margin-bottom: 3px;
				color: $resourcesBg-dark;
				font-size: 22px;
				line-height: 1.3;
			}
			table{
				width: 100%;
				td{
					width: 33%;
					padding-bottom: 5px;
				}
			}
			a{
				text-decoration: underline;
			}
		}
	}
	
}

@media (max-width: 700px){
	.blocks-style-1{
		.block{
			.img{
				float: none;
			}
			.block-content{
				margin-left: 0;
			}
		}
	}
}

.blocks-style-2{
	.block{
		background-color: #FFF1C8;
		border-radius: 15px;
		text-align: center;
		padding: 10px;
		margin-bottom: 20px;
		font-size: 14px;
		h2{
			font-size: 22px;
			color: #DBA900;
		}
		.block-text{
			line-height: 1.4;
			margin-bottom: 15px;
			min-height: 140px;
		}
		a.btn{
			margin-top: 10px;
			margin-bottom: 10px;
			background-color: #DBA900;
			color: #FFF !important;
			font-weight: bold;
		}
	}
	.row{
		> div{
			&:nth-child(3n+1){
				clear: left;
			}
			&:nth-child(even){
				.block{
					background-color: #FFE2A3;
					h2{
						color: #E17C00;
					}
					a{
						background-color: #FFA500;
					}
				}
			}
		}
	}
}
@media (max-width: 767px){
	.blocks-style-2{
		.block{
			.block-text{
				min-height: 20px;
			}
		}
	}
}

.right-block{
	background-color: $resourcesBg;
	color: #FFF;
	width: 240px;
	max-width: 50%;
	float: right;
	margin: 0 0 15px 15px;
	padding: 10px 16px;
	border-radius: 12px;
	font-size: 14px;
	line-height: 1.3;
}

.main-section {
	div.content {
		h1{
			font-size: 24px;
			font-weight: normal;
			text-transform: none;
		}
		h2{
			font-size: 18px;
			font-weight: normal;
		}
		h3, h4{
			font-size: 14px;
			font-weight: bold;
			line-height: 1.3;
			margin-top: 30px;
		}
		a{
			text-decoration: underline;
		}
		table{
			td{
				vertical-align: top;
				padding: 4px 10px 4px 0;
				width: 25%;
			}
		}
		blockquote{
			border-left: 0;
			padding: 5px 0;
			font-size: 18px;
			color: #333;
			p{
				font-size: 18px;
				color: #333;
			}
			cite{
				display: block;
				font-size: 12px;
				font-style: italic;
				text-align: right;
				color: #666;
			}
		}
		.internal-full-width{
			margin: 5px -20px;
			padding: 5px 20px;
			margin-bottom: 20px;
		}
		.internal-right-block{
			width: 96%;
			max-width: 270px;
			float: right;
			padding: 15px 2%;
			border-radius: 15px;
			margin-left: 10px;
			margin-bottom: 10px;
			@media (max-width: 550px){
				float: none;
				margin-left: 0;
			}
		}
		.border-block{
			padding: 12px;
			border-radius: 12px;
			margin-bottom: 15px;
		}
		.intro-text{
			font-size: 18px;
			color: #666;
		}
	}
	div.main-section-manage{
		@include main-section-styles($manageBg,#d18f99,#edd2d7);
	}
	div.main-section-assessment{
		@include main-section-styles($selfAssesmentBg,#f7a49e,#fcdad8);
	}
	div.main-section-resources{
		@include main-section-styles($resourcesBg,#fcd18c,#feedd1);
	}
	div.main-section-resumes{
		@include main-section-styles($resumesBg,#bfdd9f,#e6f2da);
	}
	div.main-section-linkedin{
		@include main-section-styles($linkedinBg,#91d6e6,#d1f2f9);
	}
	div.main-section-networking{
		@include main-section-styles($networkingBg,#80c7dd,#cce9f1);
	}
	div.main-section-interviewing{
		@include main-section-styles($interviewingBg,#8fafd5,#d3dfef);
	}
	div.main-section-job-offers{
		@include main-section-styles($jobOffersBg,#8f99b4,#d3d5e1);
	}
	div.main-section-onboarding{
		@include main-section-styles($onboardingBg,#a396c4,#dad5e9);
	}
	div.main-section-manage-career{
		@include main-section-styles($managingCareerBg,#b18fb2,#e0d2e1);
	}

}
