// tooltipster theme
.tooltipster-dashboard {
	border-radius: 0px; 
	border: 1px solid #333;
	background: #333;
	color: #D5DDD8;
	max-width: 180px;
}
.tooltipster-dashboard .tooltipster-content {
	font-family: Arial, sans-serif;
	font-style: italic;
	font-size: 12px;
	line-height: 14px;
	padding: 15px 10px;
}

// tooltip container
.tooltip-container{
	position: absolute;
	top: 10px;
	right: 10px;
	width: 30px;
	height: 30px;
	background: url('../img/icon-info.svg') center no-repeat;
	opacity: 0.75;
	cursor: pointer;
	@include transition( opacity 0.3s );
	&:hover{
		opacity: 1;
	}
}