.companies-right-col{
	width: 26.4%; // 238px
	float: right;
	.filters-content{
		padding: 15px;
		padding-top: 0;
		background: #FFF;
		border-radius: 25px;
		margin-bottom: 20px;
		overflow: hidden;
		.title{
			margin: 0 -15px;
			padding: 20px 15px 10px 15px;
			margin-bottom: 15px;
			font-size: 18px;
			background-color: #FFB819;
			color: #FFF;
		}
		.subtitle{
			padding: 10px 0 12px 0;
			font-size: 24px;
			color: $resourcesBg;
			font-weight: bold;
		}
	}
}

@media (max-width: 800px){
	.companies-right-col{
		width: auto;
		float: none;
	}
}

.company-items{
	margin-bottom: 20px;
	a{
		display: block;
		margin-bottom: 3px;
		color: $bgBlue;
	}
}

.company-linkedin-hoovers-links{
	margin-top: 30px;
	h2{
		color: $networkingBg;
		border-bottom: 1px solid $networkingBg;
		padding: 6px 0;
		margin-bottom: 20px;
	}
	.company-links-title{
		margin-top: 10px;
		margin-bottom: 10px;
		border-top: 1px solid $networkingBg;
		padding-top: 20px;
		font-weight: bold;
		color: #777;
	}
	a{
		display: inline-block;
		margin-right: 20px;
		max-width: 130px;
		img{
			display: block;
			width: 100%;
			height: auto;
		}
	}
}

.main-section .linkedin-button-container{
	margin-top: 40px;
	.btn-linkedin{
		background-color: $linkedinBg;
		border: 1px solid darken($linkedinBg, 5%);
		color: #FFF;
		font-weight: bold;
		font-size: 16px;
		text-decoration: none;
		@include transition(all 0.3s);
		&:hover{
			background-color: darken($linkedinBg, 3%);
			color: #FFF;
		}
	}
}

.company-linkedin-widget-container{
	clear: both;
	margin-top: 10px;
}