.welcome-screen{
	.intro{
		border-radius: 20px 20px 0 0;
		background: #4F738A;
		overflow: hidden;
		.intro-content{
			color: #FFF;
			font-size: 16px;
			h2{
				color: #FFF;
				font-size: 20px;
				font-weight: bold;
				font-style: italic;
				letter-spacing: 1px;
			}
			.top-intro{
				height: 480px;
				background: url('../img/birds/sky.jpg') bottom center;
				background-size: cover;
				padding: 30px;
				h1{
					font-family: 'Arial Black', Arial, sans-serif;
					margin: 0;
					line-height: 0.8;
					font-weight: normal;
					font-size: 77px;
					text-transform: none;
					color: #FF6B35;
					letter-spacing: -2px;
					span.part1{
						display: block;
						text-align: left;
						margin-left: 9%;
					}
					span.part2{
						display: block;
						text-align: right;
						margin-right: 18%;
					}
				}

			}
			.text{
				padding: 70px 40px 10px 40px;
				position: relative;
				color: #002F56;
				font-size: 18px;
				text-align: center;
				p{
					letter-spacing: 1px;
				}
				.bird{
					position: absolute;
					max-width: 340px;
					width: 32%;
					top: -290px;
					left: 30%;
					img{
						display: block;
						width: 100%;
						height: auto;
					}
				}
			}
			.last-text{
				padding: 15px 20px;
				color: #FFF;
				background: #002F56;
				font-size: 21px;
				font-weight: bold;
				text-align: center;
				letter-spacing: 1px;
			}
		}
	}
	@media (max-width: 767px){
		.intro{
			.intro-content{
				h1{
					font-size: 60px;
					span{
						font-size: 60px;
					}
				}
				.top-intro{
				}
				.text{
					padding: 20px 20px 10px 20px;
					.bird{

					}
				}
			}
		}
	}

	@media (max-width: 500px){
		.intro{
			.intro-content{
				.top-intro{
					height: 260px;
					h1{
						font-size: 44px;
						line-height: 1;
						span{
							font-size: 44px;
						}
						span.part1, span.part2{
							text-align: center;
							margin-left: 0;
							margin-right: 0;
						}
					}	
				}
				
				.top-intro{
					background-position: top left;
				}
				.text{
					.bird{
						display: none;
					}
				}
			}
		}
	}


	.welcome-blocks-container{
		background: #F0F0F0;
		border-radius: 0 0 25px 25px;
		padding: 20px 16px;
	}
	.welcome-blocks-row{
		@include clearfix();
	}
	.welcome-block{
		color: #707170;
		.welcome-content{
			padding: 20px;
			padding-bottom: 20px;
			overflow: hidden;
		}
		h2{
			text-transform: none;
			font-size: 48px;
			margin-top: 25px;
			border-bottom: 4px solid #333;
			font-weight: normal;
			padding-bottom: 5px;
		}
		.number{
			width: 190px;
			float: left;
			margin-bottom: 20px;
			img{
				display: block;
				margin: auto;
				width: 100%;
				height: auto;
			}
		}
		.text{
			margin-left: 210px;
			font-size: 16px;
			p{
				max-width: 520px;
				a{
					font-weight: bold;
				}
			}
		}
		.btn-more{
			color: #FFF;
			padding: 2px 14px;
			border-radius: 8px;
		}
	}

	.welcome-block-1{
		@include welcome-block($manageBg);
	}
	.welcome-block-2{
		@include welcome-block($selfAssesmentBg);
	}
	.welcome-block-3{
		@include welcome-block($resourcesBg);
	}
	.welcome-block-4{
		@include welcome-block($resumesBg);
	}
	.welcome-block-5{
		@include welcome-block($linkedinBg);
	}
	.welcome-block-6{
		@include welcome-block($networkingBg);
	}
	.welcome-block-7{
		@include welcome-block($interviewingBg);
	}
	.welcome-block-8{
		@include welcome-block($jobOffersBg);
	}
	.welcome-block-9{
		@include welcome-block($onboardingBg);
	}
	.welcome-block-10{
		@include welcome-block($managingCareerBg);
	}

	@media (max-width: 767px){
		.welcome-blocks-container{
			
		}
		.welcome-blocks-row{
			@include clearfix();
		}
		.welcome-block{
			margin: auto;
			margin-bottom: 10px;
			text-align: center;
			// max-width: 460px;
			.welcome-content{
				height: auto;
			}
			.number{
				float: none;
				margin: auto;
				margin-bottom: 10px;
				width: 110px;
				height: 110px;
			}
			.text{
				margin-left: 0;
				p{
					font-size: 14px;
					margin-left: auto;
					margin-right: auto;
				}
			}
			h2{
				font-size: 24px
			}
		}
	}
	@media (max-width: 600px){
		.welcome-block{
			.title{
				h2{
					font-size: 18px;
				}
			}
			.number{
				margin-bottom: 10px;
				img{
				}
			}
			.text{
				font-size: 16px;
			}
			
		}
	}
}