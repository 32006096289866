@mixin welcome-block($color){
	h2{
		color: $color;
		border-color: $color;
	}
	p{
		a{
			color: $color;
		}
	}
	.btn-more{
		background-color: $color;
		&:hover{
			background-color: darken($color, 4%);
		}
	}
}

@mixin border-gradient($color: #717271){
	position: relative;
	&:after{
		position: absolute;
		bottom: -2px;
		left: 0;
		content: "";
		height: 2px;
		width: 100%;
		@include linear-gradient(to right, rgba($color,90%) 0px, rgba($color, 0%) 20px, $fallback: transparent);
		// background-color: transparent;
	}
}

@mixin menu-subitem($color){
	ul{
		li a{
			border-top: 1px solid $color;
			&:hover{
				color: $color;
				font-weight: bold;
			}
		}
	}
	&.on-page{
		background-color: $color;
		> a{
			color: #FFF;
			&:hover{
				color: #FFF;
			}
		}
	}
	&.on-page, &.deployed{
        // background-color: $manageBg;
        ul{
            li.on-page a{
                background-color: $color;
                color: #FFF;
                font-weight: normal;
            }
        }
    }
}

@mixin main-section-styles($mainColor,$secondaryColor,$lightColor){
	div.content{
		h1, h2, h3, h4, h5, a{
			color: $mainColor;
		}
		.btn-primary{
			background-color: $mainColor;
			border-color: $mainColor;
			color: #FFF;
			text-decoration: none;
		}
		.internal-right-block{
			background-color: $lightColor;
		}
		.internal-full-width{
			background-color: $lightColor;
		}
		.internal-full-width-color{
			strong{
				color: $mainColor;
			}
		}
		.border-block{
			border: 1px solid $mainColor;
		}
	}
}
