div#menu{
    background-color: $bgGreenTransparent;
    margin-top: 0px;
    margin-bottom: 0;
    font-size: 18px;
    color: $greenGrey;
    div#quick-link{
        padding: 10px;
        padding-left: 50px;
        border-bottom: 1px solid #FFF;
        margin-bottom: 40px;
        h3{
            color: $greenGrey;
            font-weight: normal;
            margin: 0;
        }
        ul{
            li{
                a{
                    color: $greenGrey;
                    text-decoration: none;
                    padding-left: 20px;
                    line-height: 35px;
                }
                a:hover{
                    text-decoration: underline;
                    color: white;
                }
            }
        }
    }
    ul.nav-menu{
        margin: 0px;
        margin-top: 50px;
        border-top: 1px solid #FFF;
        &:after{
            top: -2px;
            bottom: auto;
        }
        // arrows
        > li.hasChildren{
            > a{
                position: relative;
                &:after{
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 8px 8px 0 8px;
                    border-color: $greenGrey transparent transparent transparent;
                    // border-style: inset;
                    @include transform (rotate(-90deg));
                    transition: all 0.5s;
                    position: absolute;
                    top: 16px;
                    right: 13px;
                    margin-top: 4px;
                }
                &:hover:after{
                    border-color: #FFF transparent transparent transparent;
                }
            }
            &.deployed{
                > a{
                    &:after{
                       border-color: $grey transparent transparent transparent; 
                       @include transform (rotate(0deg) );
                    }
                }
            }
        }

        li{
            ul.submenu{
                /*display: none;*/
                margin-left: 0;
                padding-left: 0;
                li{
                    background-color: white;
                    margin-left: 0;
                    padding-left: 0;
                    a.icon{
                        color: $bgGreen;
                    }

                }
            }
        }
        li.nav-menu-itm{
            padding: 0;
            border-width: 0px 0px 1px 0px;
            border-style: solid;
            
            
            a{
                display: block;
                padding: 12px 5px;
                padding-left: 50px;
                font-size: 17px;
                letter-spacing: 1px;
                color: $greenGrey;
                text-decoration: none;
                text-transform: capitalize;
            }
            a.icon:before{
                margin-left: -41px;
                background-size: 26px 26px;
                background-repeat: no-repeat;
                width: 26px;
                height: 26px;
                display: block;
                float: left;
                content: "";
                top: -1px;
                position: relative;
            }
            a.manage:before{
                background-image: url(../img/icons/menu-icon-01-white.svg);
            }
            a.assessment:before{
                background-image: url(../img/icons/menu-icon-02-white.svg);
            }
            a.resources:before{
                background-image: url(../img/icons/menu-icon-03-white.svg);
            }
            a.resumes:before{
                background-image: url(../img/icons/menu-icon-04-white.svg);
            }
            a.linkedin:before{
                background-image: url(../img/icons/menu-icon-05-white.svg);
            }
            a.networking:before{
                background-image: url(../img/icons/menu-icon-06-white.svg);
            }
            a.interviewing:before{
                background-image: url(../img/icons/menu-icon-07-white.svg);
            }
            a.job-offers:before{
                background-image: url(../img/icons/menu-icon-08-white.svg);
            }
            a.onboarding:before{
                background-image: url(../img/icons/menu-icon-09-white.svg);
            }
            a.manage-career:before{
                background-image: url(../img/icons/menu-icon-10-white.svg);
            }

        }
        li.nav-menu-itm.deployed{
            padding-bottom: 0px;
        }
        li.nav-menu-itm:not(.on-page):not(.deployed) a.icon:hover{
            color: white;
        }
        li.manage:not(.on-page):not(.deployed):hover, li.manage.deployed:not(.on-page) {
            a:before{
                background-image: url(../img/icons/menu-icon-01.svg);
            }
        }
        li.assessment:not(.on-page):not(.deployed):hover, li.assessment.deployed:not(.on-page) {
            a:before{
                background-image: url(../img/icons/menu-icon-02.svg);
            }
        }
        li.resources:not(.on-page):not(.deployed):hover, li.resources.deployed:not(.on-page) {
            a:before{
                background-image: url(../img/icons/menu-icon-03.svg);
            }
        }
        li.resumes:not(.on-page):not(.deployed):hover, li.resumes.deployed:not(.on-page) {
            a:before{
                background-image: url(../img/icons/menu-icon-04.svg);
            }
        }
        li.linkedin:not(.on-page):not(.deployed):hover, li.linkedin.deployed:not(.on-page) {
            a:before{
                background-image: url(../img/icons/menu-icon-05.svg);
            }
        }
        li.networking:not(.on-page):not(.deployed):hover, li.networking.deployed:not(.on-page) {
            a:before{
                background-image: url(../img/icons/menu-icon-06.svg);
            }
        }
        li.interviewing:not(.on-page):not(.deployed):hover, li.interviewing.deployed:not(.on-page) {
            a:before{
                background-image: url(../img/icons/menu-icon-07.svg);
            }
        }
        li.job-offers:not(.on-page):not(.deployed):hover, li.job-offers.deployed:not(.on-page) {
            a:before{
                background-image: url(../img/icons/menu-icon-08.svg);
            }
        }
        li.onboarding:not(.on-page):not(.deployed):hover, li.onboarding.deployed:not(.on-page) {
            a:before{
                background-image: url(../img/icons/menu-icon-09.svg);
            }
        }
        li.manage-career:not(.on-page):not(.deployed):hover, li.manage-career.deployed{
            a:before{
                background-image: url(../img/icons/menu-icon-10.svg);
            }
        }
        
        /*ON PAGE*/
        li.on-page, li.deployed{
            background-color: #FFF;
            a.icon{
                font-weight: normal;
                color: $grey;
                &:hover{
                    color: $grey;
                }
            }
            ul{
                li a:hover, li.on-page a{
                    color: #FFF;
                    transition: color 0.3s, background-color 0.3s;
                }
            }
        }
        li.manage{
            @include menu-subitem($manageBg);
        }
        li.assessment{
            @include menu-subitem($selfAssesmentBg);
        }
        li.resources{
            @include menu-subitem($resourcesBg);
        }
        li.resumes{
            @include menu-subitem($resumesBg);
        }
        li.linkedin{
            @include menu-subitem($linkedinBg);
        }
        li.networking{
            @include menu-subitem($networkingBg);
        }
        li.interviewing{
            @include menu-subitem($interviewingBg);
        }
        li.job-offers{
            @include menu-subitem($jobOffersBg);
        }
        li.onboarding{
            @include menu-subitem($onboardingBg);
        }
        li.manage-career{
            @include menu-subitem($managingCareerBg);
        }
        
        /*SUBMENU*/
    }

    ul.nav-menu{
        ul.submenu{
            li{
                border-bottom: none;
                a{
                    // letter-spacing: 0;
                    // padding-left: 70px;
                }
            }
        }
    }
    
    li:not(.deployed){
        ul.submenu{
            display: none;
        }
    }
    li.deployed ul.submenu{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        li{
            background-color: white;
            a{
                color: $grey;
                letter-spacing: 1px;
            }

        }
        li:last-child{
            border: none;
        }
    }
    
}