/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 15/04/2016, 17:59:56
    Author     : Alejandro
*/

@media only screen and (max-width: 1024px) {
	.col {  margin: 1% 0 1% 1.6%; }
    span#menu-hamburger{
        font-size: 34px;
        display: block;
        float: left;
        position: absolute;
        top: 40px;
        color: white;
        left:10px;
        cursor: pointer;
        margin-top: 30px;
    }
    
    div.search{
        form#search-form{
            display: none;
        }
        div#logo-container{
            left: 50px;
            margin-top: 0;
        }
        a#icon-anchor {
            display: block;
            height: 76px;
            width: 100%;
            img#icon{
                height: 116px;
                position: relative;
                top: -40px;
            }
        }
        
        div#top-nav-links-container{
            .section{
                height: 38px;
                line-height: 38px;
            }
            div#top-search-container{
                height: 38px;
            }
        }
    }
    
    .bodyContainer{
        max-width: 1024px;
        margin-left: auto;
        margin-right: auto;
        div.bodyWrapper {
            padding: 0px 10px;
            div#main{
                display: block;
                width:100%;
                margin: 0px;
                div#menu{
                    width: 100%;
                    float: left;
                    z-index: 1100;
                    display: none;
                    position: absolute;
                    background-color: rgb(91,127,113);
                }
                div#main-section{
                    width:100%;
                    float: left;
                    z-index: 1090;
                    margin: 1% 0px;
                }
            }
            div.title{
                padding: 12px 20px;
                h1{
                    font-size: 21px;
                }
            }
        }
        
    }
    div.bodyWrapper{
        div#menu ul.nav-menu{
            margin-top: 3px;
        }
    }
}
@media only screen and (max-width: 768px) {
    div#top-nav-links{
        div#links-container{
            width: 60%;
        }
    }
    html body{
        /*background-color: $bgGreen;*/
    }
    span#menu-hamburger{
        // top: 56px;
        // left:3%;
        // z-index: 1100;
    }
    .bodyContainer{
        // max-width: 736px;
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
        
        div.bodyWrapper {
            div#main{
                div#menu{
                    
                }
                div#main-section{
                    div.span_3_of_4{
                        width: 100%;
                    }
                    div.span_3_of_4+div.span_1_of_4{
                        width:100%;
                        margin: auto 0px;
                    }
                    div.span_1_of_4{
                        width:100%;
                        margin: auto 0px;
                    }
                    .rounded{
                        padding: 5%;
                    }
                }
            }
        }
    }
        
    
}