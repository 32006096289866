div.widget{
	div.title{
		h2{

		}
	}
	div.content{
		min-height: 40px;
		margin-bottom: 20px;
		padding: 20px 24px;
		padding-bottom: 10px;
		.feat-icon{
			max-width: 80px;
			width: 80%;
			display: block;
			margin: auto;
			padding: 5px 0;
		}
		.btn{
			display: block;
			width: 100%;
			white-space: normal;
			border-radius: 12px;
			margin-bottom: 15px;
			padding: 3px;
			font-size: 13px;
			font-weight: bold;
			text-decoration: none;
			@include transition( background 0.3s, color 0.3s );
		}
		.btn-container{
			.btn{
				display: inline-block;
				width: auto;
			}
		}
		.table-container{
			margin-left: -24px;
			margin-right: -24px;
		}
		.table{
			th, td{
				border-top: none;
				padding-left: 24px;
			}
			th{
				text-align: left;
				color: $resourcesBg;
				font-weight: bold;
				font-size: 18px;
				padding-bottom: 14px;
			}
			td{
				color: #777;
				font-weight: bold;
				a{
					color: #777;
				}
			}
			tr:nth-child(odd){
				td{
					background-color: #F9E7D7;
				}
			}
		}
	}
}

div.widget-resumes{
	div.content{
		.btn{
			background-color: $companiesBg;
			color: #FFF;
			margin-bottom: 20px;
			// border-radius: 0;
			&:hover{
				background-color: $resumesBg;
			}
		}
		.image-label{
			background-color: $resumesBg;
			border-radius: 15px;
			padding: 10px;
			display: block;
			max-width: 128px;
			margin: auto;
			transition: background 0.3s;
			img{
				max-width: 110px;
				height: auto;
			}
			&:hover{
				background-color: $companiesBg;
			}
		}
	}
}

div.widget-interviewing{
	div.content{
		background: #082E6D;
		padding: 0 15px;
		overflow: hidden;
		.col-bg{
			background: url('../img/widget-interv.jpg') bottom left no-repeat;
			height: 155px;
		}
		.col-buttons{
			margin-top: 15px;
		}
		.btn{
			background: #FCFFFF;
			color: #082E6D;
			font-weight: bold;
			&:hover{
				background: $interviewingBg;
				color: #FFF;
			}
		}
		.btn-first{
			margin-bottom: 20px;
		}
		.btn-primary{
			background: #72C4D7;
			border-color: #FFF;
			font-size: 16px;
			padding: 15px 15px;
			text-transform: uppercase;
		}

	}
}
div.widget-searches, div.widget-jobs{
	div.content{
		.btn{
			background-color: #FAD000;
			color: #FFF;
			padding: 15px 8px;
			&:hover{
				background-color: $resourcesBg;
			}
		}
	}
}
div.widget-jobs{
	div.content{
		.btn{
			background-color: $resourcesBg;
			padding: 13px 15px;
			margin-right: 12px;
			&:hover{
				background-color: $resourcesBg-hover;
			}
		}
	}
}

div.widget-searches{
	div.content{
		min-height: 300px;
		.info{
			@include clearfix();
			text-align: center;
		}
		.row-totals{
			color: #777;
			font-size: 16px;
			margin-bottom: 10px;
			padding-bottom: 10px;
			> div{
				padding-top: 4px;
				padding-bottom: 10px;
			}
			> div:first-child{
				border-right: 1px solid $resourcesBg;
			}
			.total{
				font-size: 120px;
				font-weight: normal;
				line-height: 1;
			}
		}
	}
	.quick-search-form{
		padding-bottom: 10px;
		margin: 0 auto 10px auto;
		max-width: 300px;
		form{
			float: none;
			width: auto;
			input[type="text"]{
				border-color: $resourcesBg;
			}
			span#submit{
				background-color: $resourcesBg;
			}
			

		}
	}
}

div.widget-resources{
	div.content{
		table{
			border: none;
			width: 92%;
			text-align: center;
			margin: auto;
			margin-bottom: 18px;
		}
		td{
			border: 1px solid $selfAssesmentBg;
			vertical-align: middle;
			padding: 15px 0 20px 0;
			width: 50%;
			text-transform: uppercase;
			color: $selfAssesmentBg;
			font-weight: bold;
			font-size: 17px;
			&.no-top{
				border-top: none;
			}
			&.no-bottom{
				border-bottom: none;
			}
			&.no-left{
				border-left: none;
			}
			&.no-right{
				border-right: none;
			}
			a{
				color: $selfAssesmentBg;
				display: block;
				line-height: 1.1;
			}
			img{
				display: block;
				width: auto;
				height: 50px;
				margin: 15px auto;
				
			}
		}
	}
}

div.widget-linkedin{
	div.content{
		padding-top: 5px;
		.feat-icon{
			max-width: 94px;
			margin: 8px auto;
		}
		.btn-small{
			border: 1px solid $linkedinBg;
			margin-top: 14px;
			margin-bottom: 4px;
			font-size: 17px;
			font-weight: bold;
			color: #008FBE;
			padding: 10px 16px;
			&:hover{
				color: $linkedinBg;
			}
		}
		.btn-primary{
			font-size: 17px;
			background-color: $linkedinBg;
			margin: 0 auto;
			margin-top: 12px;
			padding: 4px 10px;
			max-width: 260px;
			&:hover{
				background-color: $interviewingBg;
			}
		}
	}
}

div.widget-contacts, div.widget-companies{
	div.content{
		background-color: #00A885;
		padding-top: 10px;
		padding-bottom: 10px;
		.feat-icon{
			max-width: 98px;
		}
	}
}
