/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 07/04/2016, 17:42:08
    Author     : Alejandro
*/
/*import css reset*/

@import "_bourbon/_bourbon.scss";


/* COLOR DEFITION */
$blue: #213469;
$lightBlue: #c7d3e7;
$bgGreen: #5b7f71;
$bgGreenTransparent: rgba(91,127,113,0.9);
$bgGreenTransparent10: rgba(91,127,113,0.3);
$bgBlue: #005DB9;

$manageBg: #a41f35;
$selfAssesmentBg: #ff4338;
// $resourcesBg: #ff5000;
$resourcesBg: #ffa509;
    $resourcesBg-hover: #FF8C00;
    $resourcesBg-dark: #E17C00; 
$resumesBg: #82bc00;
$linkedinBg: #00c1de;
$networkingBg: #008fbe;
$interviewingBg: #005cb9;
$jobOffersBg: #002e6d;
$onboardingBg: #430098;
$managingCareerBg: #622066;
$companiesBg: #007B5F;
$grey: #717271;
$greenGrey: #d5ddd9;


@import 'mixins';
@import "ios-checkbox";

// @import 'reset';
@import 'footer';
@import 'tooltip';
@import "menu";

html{
    font-family: Arial, sans-serif;
    font-size: 18px;
    color: white;
}

body{
    font-family: Arial,Helvetica,sans-serif;
}

ol, ul {
    list-style: none;
}
ol, ul, li{
    padding: 0;
    margin: 0;
}
table, tr, td{
    border: 0;
}
table{
    width: 100%;
}

/*HEADERS*/
body h1{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
}
body h2{
    font-weight: bold;
    font-size: 16px;
    color: #02587d;
}
body h3{
    font-weight: bold;
    font-size: 18px;
    line-height: 45px;
    color: #015051;
}
body h4{
    font-weight: bold;
    font-size: 16px;
    color: #02587d;
}
body h5{
    font-weight: bold;
    font-size: 14px;
    color: #000000;
}
.h6, body h6{
    font-weight: bold;
    font-style: italic;
    font-size: 14px;
    color: #76c4d5;
}

.btn-primary{
    background-color: $bgBlue;
    border: 1px solid $bgBlue;
    &:hover{
        background-color: darken($bgBlue, 8%);
    }
}
.btn-secondary{
    background-color: #E6E6E6;
    color: $bgBlue;
    border: none;
    &:hover{
        background-color: #EEE;
        color: $bgBlue;
    }
}

.ui-tooltip-inner{
    background: #000;
    color: #FFF;
}

.alert{
    border-radius: 15px;
    font-size: 17px;
}
.alert-success{
    border-color: #5b7f71;
    color: #5b7f71;
}

.form-control{
    background-color: #e6e6e6;
    border: none;
    box-shadow: none;
    // padding: 5px;
    color: #777;
}

.main-section div.content {
    font-size: 14px;
    line-height: 22px;
    color: #333;
    strong, b{
        font-weight: bold;
    }
    ul, ol{
        margin: 20px 0 20px 0;
        padding-left: 28px;
    }
    ul > li{
        list-style-type: disc;
        margin-bottom: 5px;
    }
    
    ol > li{
        list-style-type: decimal;
        margin-bottom: 10px;
    }
    ol ul{
        margin-left: 2%;
    }
    a{
        // color: #e48425;
        &.btn-primary{
            color: #FFF;
        }
    }
    /*a:hover{
        font-weight: bold;
    }*/
    img {
        max-width: 100%;
    }
    .intro-text{
        color: #666;
        font-size: 18px;
    }
}

.tabs-container{
    @include clearfix();
    margin-left: -20px;
    margin-right: -20px;
    .resource-tab{
        span{
            display: inline-block;
            width: 85%;
            position: relative;
            text-align: left;
            
            &:after{
                content: "";
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 10px 0 10px;
                border-color: #FFF transparent transparent transparent;
                // border-style: inset;
                @include transform (rotate(-90deg));
                transition: all 0.5s;
                position: absolute;
                top: 2px;
                right: -20px;
                margin-top: 4px;
            }
        }
        &.active{
            span{
                &:after{
                    border-color: $resourcesBg transparent transparent transparent;
                    @include transform (rotate(0deg) );
                }
            }
        }
        &:nth-child(odd){
            border-right: 1px solid #FFF;
        }
    }
}
.resource-tab{
    border: none;
    border-bottom: 1px solid #FFF;
    cursor: pointer;
    float: left;
    font-size: 1.3em;
    font-weight: bold;
    padding: 19px 10px 19px 20px;
    text-align: left;
    width: 50%;
    background-color: #e27c00;
    color: #FFF;
    @include transition(background 0.3s, color 0.3s);
}
.resource-tab:hover{
    background-color: lighten(#e27c00, 5%);
} 

.resource-tab.active{
    background-color: #FFF;
    color: $resourcesBg;
}
.resource-content{
    @include clearfix();
    padding: 10px 0;
}

/* TABLES */
.custom-table{
    width: 100% !important;
    border-top: 1px solid #CCC;
    td, th{
        padding: 10px 3px;
        border-bottom: 1px solid #CCC; 
    }
    th{
        text-align: left !important;
    }
}



/*  SECTIONS  */
.section {
	clear: both;
	padding: 0px;
	margin: 0px;
        width: 100%; /*<==added by me....*/
}

/*  COLUMN SETUP  */
.col {
	display: block;
	float:left;
	margin: 12px 0 12px 1.6%;
}
.col:first-child { margin-left: 0; }

/*  GROUPING  */
.group:before,
.group:after { content:""; display:table; }
.group:after { clear:both;}
.group { zoom:1; /* For IE 6/7 */ }

/* FLOATING*/
.right{
    float: right;
}
.left{
    float: left;
}

/* PAGINATION */
.pagination{
    overflow: hidden;
    span{
        display: inline-block;
    }
    .left{
        float: left;
        text-align: left;
    }
    .right{
        float: right;
        text-align: right;
    }
    a{
        display: inline-block;
        padding: 5px;
    }
}

/* ICON-SEARCH BAR*/

/*  GRID OF FOUR  */
.span_4_of_4 {
	width: 100%;
}
.span_3_of_4 {
	width: 74.6%;
}
.span_2_of_4 {
	width: 49.2%;
}
.span_1_of_4 {
	width: 23.8%;
}

/*
CONFIGURING COLORS
*/
.green{
    background-color: $bgGreen;
}
.blue{
    background-color: $blue;
    color: white;
    font-size: 24px;
    line-height: 150%;
    a{
        color: #76c4d5!important;
    }
}
.light-blue{
    background-color: $lightBlue;
    font-size: 24px;
    line-height: 150%;
    color: #213469;
    a{
        color: #213469!important;
    }
}

.rounded{
    border-radius: 17px;
    padding: 10%;
}

body{
    /*background: #5b7f71 url('../img/CD_background_1600x1800.png');*/
    background-color: white;
}

.bodyContainer{
    
}
.center-container{
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}
#main-section{
    margin-top: 25px;
}
span#menu-hamburger{
    display: none;
}

.quick-search-form{

    form{
        width: 50%;
        height: 45px;
        float: right;
        margin-bottom: 10px;
        position: relative;
        input[type="text"]{
            width: 100%;
            height: 48px;
            vertical-align: middle;
            border-radius: 10px;
            border: 1px solid $bgGreen;
            padding-left: 5px;
            padding-right: 39px;
            font-weight: normal;
            line-height: 35px;
            
        }
        span#submit{
            background: $bgGreen url('../img/icon-search.svg') center no-repeat;
            height: 48px;
            position: absolute;
            float: right;
            top: 0px;
            width: 44px;
            right: 0px;
            border-radius: 0px 10px 10px 0px;
            border-style: none;
            color: white;
            font-size: 20px;
            line-height: 31px;
            text-align: center;
            cursor: pointer;
            transition: background 0.3s;
            &:hover{
                background-color: #5F9671;
            }
        }
    }

}
.search{
    height: 10%;
    padding-top: 30px;
    /*background-color: white;*/
    font-weight: bold;
    background: transparent url('../img/world.jpg') no-repeat right top;
    background-size: cover;
    div#logo-container{
        position:absolute;
        margin-top: 24px;
    }
    div.col{
        margin: 0px;
    }
    .span_1_of_4 {
        width: 23.8%;
    }
    .span_2_of_4 {
            width: 47.6%;
    }
    .span_3_of_4{
        width: 76.2%;
    }
    a#icon-anchor {
        display: block;
        height: 90px;
        width: 100%;
        img#icon{
            height: 138px;
            position: relative;
            top: -48px;
        }
    }
 
    div.col{
        margin-top: 0px;
        margin-bottom: 0px;
    }
    div#top-nav-links-container{
        .section{
            height: 45px;
            line-height: 45px;
        }
        .user-info{
            img{
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 3px;
            }
        }
        div#top-search-container{
            height: 69px;
        }
        div#top-nav-links{
            background-color: $bgGreen;
            a{
                color: #d3d8d6;
            }
            a:hover{
                color: #ffffff;
                cursor: pointer;
            }
            
            #links-container{
                float: right;
                span{
                    margin-top: 0px;
                    margin-bottom: 0px;
                    text-align: center;
                    border-left: #A4B3AC solid 1px;
                    box-sizing: border-box;
                    color: #FFF;
                    font-size: 18px;
                }
                span:first-child{
                    text-align: right;
                    border: none;
                    margin-right: 1.6%;
                }
                
            }
        }
    }
}

div.title{
    position: relative;
    flex: 1;
    background-color: $bgBlue;
    padding: 12px 20px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    &.manage{
        background-color: $manageBg;
    }
    &.assessment{
        background-color: $selfAssesmentBg;
    }
    &.resources{
        background-color: $resourcesBg;
    }
    &.resumes{
        background-color: $resumesBg;
    }
    &.linkedin{
        background-color: $linkedinBg;
    }
    &.networking{
        background-color: $networkingBg;
    }
    &.interviewing{
        background-color: $interviewingBg;
    }
    &.job-offers{
        background-color: $jobOffersBg;
    }
    &.onboarding{
        background-color: $onboardingBg;
    }
    &.manage-career{
        background-color: $managingCareerBg;
    }
    &.companies{
        background-color: $companiesBg;
    }
    h1, h2{
        color: #ffffff;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 24px;
        margin-top:0px;
        margin-bottom: 0px;
    }
    a{
        font-size: 18px;
        color: #FFF;
        font-weight: normal;
        display: inline-block;
        margin: 0 5px;
        text-decoration: underline;
        text-transform: none;
    }

}

div.bodyWrapper{
    background: #A9BBB3 url('../img/CD_background_1600x1800.png') top left no-repeat;
    padding: 0px 20px;
    color: #000;
    font-size: 14px;
    div.content{
        background-color: white;
        min-height: 631px;
        padding: 20px;
        border-radius: 0 0 25px 25px;
        &.content-no-padding{
            padding: 0 20px;
            min-height: 260px;
            overflow: hidden;
        }
        .content-inner{
            padding: 20px 0;
        }
        table{
            
            tbody{
                th{
                    font-weight: bold;
                    text-align: center;
                }
                // tr:hover td{
                //     background-color: $bgGreenTransparent10;
                // }
            }
        }
    }
}

div.bodyWrapper_Small{
    div.content{
        min-height: 100px;
    }
}

div#menu, div#main-section{
    flex-grow: 1;
}
div#main{
    min-height: 631px;
    display: flex;
}
.clearfix:after { 
   content: " ";
   display: block; 
   height: 0; 
   clear: both;
}

@import "welcome";
@import "resumes";
@import "popups";
@import 'widgets';
@import 'tablet';
@import 'phone';
@import "companies";
@import 'jobs';
@import 'jobleads';
@import "pages";