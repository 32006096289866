ul.ui-sortable{
	width: 90%;
	list-style: none;
}
#sortable1, #sortable2 { 
	list-style-type: none; 
	margin: 0; 
	float: left;
	background: #f6f6f6; 
	padding: 10px; 
	width: 99%;
	min-height: 290px;
	border: 1px dashed #bbb;
}
#sortable1 li, #sortable2 li { 
	margin: 5px; 
	padding: 6px 10px; 
	font-size: 1.1em; 
	cursor: move;
	list-style: none !important;
}

.resume-item{
	font-size: 18px;
	padding: 10px 0;
}