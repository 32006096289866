.joblead-item{
	margin-top: 15px;
	font-weight: bold;
	font-size: 17px;
	.date{
		margin-top: 5px;
		font-weight: normal;
		display: block;
		color: #777;
		font-size: 14px;
	}
	&.visited{
		a{
			color: #bbb !important;
		}
	}
}