.jobs-filters-container{
	.filters-content{
		padding: 40px 15px;
		background: #FFE2A3;
		border-radius: 0;
		margin-bottom: 20px;
		overflow: hidden;
	}
	.search-form{
		.fields{
		}
		.form-control{
			display: block;
			width: 99%;
			background-color: #F2F2F2;
			border: none;
			box-shadow: none;
			padding: 5px;
			color: #777;
			margin-bottom: 10px;
		}
		.form-select{
			// height: 32px;
		}
		.form-buttons{
			text-align: center;
			.btn{
				background-color: $resourcesBg;
				color: #FFF;
				font-size: 20px;
				text-transform: uppercase;
				padding: 3px 15px;
				border-radius: 8px;
				font-weight: bold;
				letter-spacing: 1px;
				margin-bottom: 5px;
				min-width: 130px;
			}
			.btn-clear-form{
				font-size: 16px;
				padding: 9px 20px;
				background-color: #BBB;
			}
		}
	}
	.open-save-search{
		display: block;
		margin-top: 4px;
		cursor: pointer;
		color: $resourcesBg;
		text-decoration: underline;
	}
	.save-search-form{
		display: none;
	}
}

@media (min-width: 800px){
	.jobs-filters-container{
		.search-form{
			position: relative;
			.fields{
				margin-right: 200px;
			}
			.form-buttons{
				position: absolute;
				top: 0;
				right: 0;
				width: 180px;
			}
		}
	}
}

.search-service-tab{
	position: relative;
	.header{
		position: absolute;
		top: -35px;
		right: 0;
		overflow: hidden;
		padding-bottom: 10px;
		color: #707170;
		.site{
			float: right;
			margin-right: 0px;
			img{
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}

.jobs-results-container{
	float: left;
	width: 72%;
	margin-bottom: 20px;
	h1{
		color: $resourcesBg;
	}
	.job-item{
		border-bottom: 1px solid #FFA300;
		&:last-child{
			border-bottom: 0;
		}
	}
}

.job-item{
	padding: 20px 0;
	color: #707070;
	font-size: 13px;
	
	.title{
		color: $resourcesBg-dark;
		font-size: 13px;
		font-weight: bold;
		margin-bottom: 5px;
	}
	.info{
		font-weight: bold;
		margin-bottom: 5px;
		.company{
			display: inline-block;
			color: #707070;
		}
		.location{
			
		}
	}
	.text{
		margin-bottom: 5px;
	}
	.date{
		font-style: italic;
	}
	.save-job{
		color: $resourcesBg-dark;
		text-decoration: none;
		cursor: pointer;
		&:before{
			content: "+ "
		}
	}
	.saved{
		color: #FFC842;
	}
}

.load-more{
	.btn-more{
		color: #FFF;
		font-weight: bold;
		background-color: $resourcesBg;
		&:hover{
			background-color: darken($resourcesBg, 4%);
			color: #FFF;
		}
	}
}


.jobs-right-col{
	width: 26.4%; // 238px
	float: right;
	.right-col-content{
		padding: 8px;
	}
	.title{
		margin: 0px;
		padding: 10px 6px;
		margin-bottom: 0px;
		color: #FFF;
		border-radius: 0;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: bold;
		.tooltip-container{
			background-size: 20px 20px;
			width: 20px;
			height: 20px;
		}
	}
	.subtitle{
		padding: 10px 0 12px 0;
		font-size: 24px;
		color: $resourcesBg;
		font-weight: bold;
	}
	.total{
    	border-radius: 6px;
    	background-color: $selfAssesmentBg;
    	padding: 0px 10px;
    	margin-left: 5px;
    }
	.right-col-block{
		background: #FFF;
		&:last-child{
			border-radius: 0 0 20px 0;
		}
	}
	.btn-container{
		margin-top: 15px;
		margin-bottom: 15px;
		.btn-primary{
			background-color: $resourcesBg;
			color: #FFF;
			border: none;
			border-radius: 10px;
		}
	}
}

.carrousel-item{
	font-size: 12px;
	font-weight: bold;
	color: $resourcesBg-dark;
	border-top: 1px solid $resourcesBg;
	padding: 5px 0;
	.carrousel-title{
		cursor: pointer;
		position: relative;
        &:after{
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 7px 0 8px;
            border-color: #FFC842 transparent transparent transparent;
            // border-style: inset;
            @include transform (rotate(-90deg));
            transition: all 0.3s;
            position: absolute;
            top: 5px;
            right: 1px;
            margin-top: 0px;
        }
	}
	.carrousel-content{
		display: none;
		.actions{
			padding: 16px 0;
			a{
				color: $resourcesBg-dark;
				margin-right: 20px;
				img{
					width: 14px;
					height: 14px;
				}
			}
		}
		.alert_info{
			margin-bottom: 15px;
			.alert-text{
				margin-right: 20px;
			}
			input.ios8-switch{
				opacity: 0.1;
			}
		}
		.go-search{
			.btn{
				font-size: 14px;
				color: #FFF;
				background-color: #FFD100;
				font-weight: bold;
				padding: 2px 5px;
				border-radius: 10px;
				margin-bottom: 15px;
				&:hover{
					background-color: $resourcesBg;
				}
			}
		}
	}
	&:last-child{
		border-bottom: 1px solid $resourcesBg;
	}
	&.isOpen{
		.carrousel-title{
			&:after{
               @include transform (rotate(0deg) );
            }
		}
		.carrousel-content{
			display: block;
		}
	}
	
}

.saved-jobs-list{
	.option{
		display: block;
		font-size: 12px;
		font-weight: bold;
		color: $resourcesBg-dark;
		border-top: 1px solid $resourcesBg;
		padding: 5px 0;
		cursor: pointer;
		position: relative;
		&:after{
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 7px 0 8px;
            border-color: #FFC842 transparent transparent transparent;
            // border-style: inset;
            @include transform (rotate(-90deg));
            transition: all 0.3s;
            position: absolute;
            top: 10px;
            right: 1px;
            margin-top: 0px;
        }
        &:last-child{
			border-bottom: 1px solid $resourcesBg;
		}
        .option-title{
        	margin-right: 15px;
        }

	}
}

@media (max-width: 800px){
	.jobs-right-col, .jobs-results-container{
		width: auto;
		float: none;
	}
}


div.jobs-options-container{
	@include clearfix();
	clear: both;
	margin-top: 20px;
	div.content{
		min-height: 100px;
	}
}

// JOBS TABS
.small-tabs-container{
    @include clearfix();
}
.small-tab{
    display: inline-block;
    background: #E6E6E6;
    padding: 7px 7px;
    margin-right: 0px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: -1px;
    margin-top: 10px;
    color: #B3B3B3;
    &:first-child{
    	border-radius: 10px 0 0 10px;
    }
    &:last-child{
    	border-radius: 0 10px 10px 0;
    }
}
.small-tab:hover{
    background-color: #ddd;
}
.small-tab.active{
    background-color: $blue;
    color: #FFF;
    font-weight: bold;
}
.small-tab-content{
    @include clearfix();
    margin-top: 10px;
    padding: 20px 0;
    border-top: 2px solid $resourcesBg;
}

.small-tab.indeed-tab{
	.logo{
		width: 50px;
		margin: 0 15px;
		height: 20px;
		background: url('../img/search/indeed-gray.svg') center no-repeat;
	}
	&.active{
		background-color: #4567AF;
		.logo{
			background-image: url('../img/search/indeed-white.svg');			
		}
	}
}
.small-tab.glassdoor-tab{
	.logo{
		width: 80px;
		height: 20px;
		background: url('../img/search/glassdoor-gray.svg') center no-repeat;
	}
	&.active{
		background-color: #7CB028;
		.logo{
			background-image: url('../img/search/glassdoor-white.svg');			
		}
	}
}

// SAVED JOBS

.saved-jobs-details-container{
	border-radius: 10px;
	padding: 15px;
	min-height: 180px;
	.saved-job-item{
		display: none;
		.title{
			font-size: 22px;
			margin-bottom: 10px;
			color: $resourcesBg;
		}
		.show-edit-off{
			display: block;
		}
		.show-edit-on{
			display: none;
		}
		.popup-field-row{
			margin-bottom: 9px;
			font-size: 14px;
			.popup-field-name{
				color: #E17C00;
			}
			.popup-field-text{
				a{
					color: #E17C00;
					text-decoration: underline;
				}
			}
		}
		.btn-primary{
			color: #FFF;
			display: inline-block;
			padding: 5px 25px;
			min-width: 120px;
			background-color: #FFD200;
			border: none;
		}
		&.active{
			display: block;
		}
		&.on-edit{
			.show-edit-off{
				display: none;
			}
			.show-edit-on{
				display: block;
			}
		}
	}
	.saved-job-edit, .saved-job-delete{
		display: inline-block;
		text-decoration: underline;
		cursor: pointer;
	}
	.form-item-container{
		padding-bottom: 10px;
	}
}

// PROGRESS

.job-item-progress{
	border-top: 2px solid $resourcesBg;
	h3{
		margin-top: 10px;
		line-height: 1.5;
		font-size: 22px;
		color: $resourcesBg;
	}
	.job-item-progress-list{
		.progress-item{
			margin-bottom: 10px;
			.progress-name{
				display: inline-block;
				min-width: 120px;
				font-weight: bold;
			}
		}
	}
	.job-item-progress-form{
		margin-top: 10px;
		.form-group{
			margin-right: 10px;
		}
		.btn-container{
			margin-top: 15px;
		}
		.btn-primary{
			background-color: #FFD200;
			border: none;
			text-transform: uppercase;
		}
	}
}

.popup-form{
	color: #707170;
	h2{
		color: $resourcesBg;
		text-transform: uppercase;
		margin-bottom: 20px;
		font-size: 22px;
	}
	.form-group{
		margin-bottom: 25px;
		label{
			font-weight: normal;
			display: block;
			margin-bottom: 7px;
			color: #707170;
			&.inline{
				display: inline-block;
				vertical-align: top;
			}
		}
		.alert-text{
			display: inline-block;
			margin-right: 30px;
			line-height: 30px;
			vertical-align: middle;
		}
	}
	.btn{
		background-color: #FFD200;
		color: #FFF;
		text-transform: uppercase;
		font-weight: bold;
		border: none;
		&:hover{
			background-color: darken(#FFD200, 3%);
		}
	}
}

