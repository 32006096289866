.main-footer{
	background: #4d4d4d;
	color: #FFF;
	padding: 30px 24px;
	font-size: 17px;
	a{
		color: #FFF;
	}
	li{
		padding: 11px 0;
	}
	.footer-info{
		margin-top: 5px;
		a{
			&:hover{
				color: #FFF;
				text-decoration: underline;
			}
		}
	}
	.footer-links{
		margin-top: 5px;
		margin-right: 10px;
		a{
			display: block;
			text-align: right;
			margin-bottom: 10px;
		}
	}
}